import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

const LogoImage = ({ imgName }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fixed(width: 180) {
                ...GatsbyImageSharpFixed
                originalName
              }
            }
          }
        }
      }
    `}
    render={data => {
      let image = data.allImageSharp.edges.find(
        edge => edge.node.fixed.originalName === `${imgName}.png`
      )
      if (!image) {
        image = data.allImageSharp.edges.find(
          edge => edge.node.fixed.originalName === `kategoriaDefault.jpg`
        )
      }
      return <Img fixed={image.node.fixed} alt={imgName} />
    }}
  />
)
export default LogoImage
