import React from "react"
import styled from "styled-components"

const FooterComponent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  background-color: #2f2f2f;
  color: white;
  height: 100px;
  margin: 50px 0px 0px 0px;
  text-align: center;
  align-items: center;
  font-size: 1rem;
`

const Link = styled.a`
  text-decoration: none;
  color: white;
  font-size: 1rem;
`

const Footer = () => {
  return (
    <FooterComponent>
      <div>© Copyright 2020 Halvinliittyma.fi </div>
      <Link href="/tietoa-palvelusta">
        <div>Lisätietoa palvelusta</div>
      </Link>
      <Link href="/ota-yhtetta">
        <div>Ota yhteyttä</div>
      </Link>
    </FooterComponent>
  )
}

export default Footer
