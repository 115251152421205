import React from "react"
import MenuBar from "../layout/MenuBar"
import { createGlobalStyle } from "styled-components"
import Footer from "../layout/Footer"
import CookieConsent from "react-cookie-consent"
import { ScrollUp } from "../utils/ScrollUp"
import { NotificationBar } from "../ads/notificationBar/NotificationBar"
import { ChakraProvider } from "@chakra-ui/react"

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #f9f8fd;
    padding: 0px;
    margin: 0px;
    font-size: 1.2rem;
    line-height: 28px;
    width: 100%;
    font-family: Montserrat, 'Open Sans', 'Helvetica Neue', sans-serif,Arial, Helvetica, sans-serif,open-sans,sans-serif;
  }
  a { cursor: pointer; font-size: 1.4rem; font-weight: 800; text-decoration: none;  }
  h1 {line-height: 1.2;}
  h2 {line-height: 1.2; 
  margin-top: 1rem;
  color: #a35dbc;
  font-weight: 600;
  font-size: 2.5rem;
  }
  h3 {line-height: 1.2; 
  margin-top: 1rem;
  color: black;
  font-weight: 600;
  font-size: 2.2rem;
  ;}
  h4 {line-height: 1.2; 
  margin-top: 1rem;
  color: black;
  font-size: 2rem;
  font-weight: 300;
  }

  i{
    font-size: 1.6rem;
    font-weight: 900;
  }

  h5 {line-height: 1.2; 
  margin-top: 1rem;
  color: #a35dbc;
  font-weight: 800;
  font-size: 2rem;
  }
  li{
    padding: 1rem 0.2rem;
  }
  p{
    padding: 1rem 0rem;
    
  }
  svg{
    color: red;
    height: 50px;
    width: 50px;
  }
  img{
    
  }


  .affiliateLink{
    font-weight: 800;
    font-size: 1.5rem;
  }

  .checkPrice{
    display: inline-block;
    padding: 1rem 2rem;
    color: white;
    background-color: orangered;
    border-radius: 15px;
    text-align: center;
    max-width: 200px;
    margin-bottom: 8rem;
    text-decoration: none;
    margin-top: 2rem
  }

  .checkPrice:hover{
    background-color: red;
  }
  
.simTable{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .simRow{
    display: flex;
    justify-content: space-between;
    border-bottom:1px solid #878787;
  }

  .simItem{
    padding: 0.2rem 0rem;
    font-size: 0.9rem;
    font-weight: 600;
  }
  
 
  button { cursor: pointer;}

  .points{
    color: black;
    font-weight: 800;
    padding: 4rem;
  }
`

const GlobalStyles = props => {
  return (
    <div>
      <ChakraProvider>
        <CookieConsent
          location="bottom"
          buttonText="Ok"
          cookieName="lpCookie"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          Tällä sivustolla käytetään evästeitä. Jatkamalla sivuston käyttöä
          hyväksyt evästeiden käytön.
        </CookieConsent>
        <MenuBar blog={props.blog} />
        {props.children}
        <Footer />
        <ScrollUp />
      </ChakraProvider>
      <GlobalStyle />
    </div>
  )
}

export default GlobalStyles
