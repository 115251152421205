import React from "react"
import { Helmet } from "react-helmet"

const MetaDetails = props => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,minimum-scale=1.0 maximum-scale=1.0"
      />
      <title>{props.title}</title>
      <meta name="description" content={props.desc} />
      <meta
        name="google-site-verification"
        content="XBPQXaafxTIjPrEtRlKxdtST_giionkamVh9IIW7Cqo"
      />
      <html lang="fi" hrefLang="fi" />
    </Helmet>
  )
}

export default MetaDetails
