import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { device } from "../../utils/mediaQueries"
import { FaBars } from "react-icons/fa"
import LogoImage from "../images/LogoImage"

const NavBar = styled.div`
  grid-gap: 3px;
  color: black;
  font-weight: 700;
  font-size: 17px;
  align-content: -webkit-center;
  padding: 2rem 9rem;
  background-color: white;
  justify-content: center;
  align-items: center;
  @media ${device.tablet} {
    display: none;
  }
`

const NavBarMobile = styled.div`
  display: none;
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 10px;
  background-color: white;

  color: white;
  max-height: 5000px;
  align-content: flex-start;
  text-align: flex-start;
  font-weight: 700;
  font-size: 12px;
  margin: 0px 0.2rem;
  justify-content: center;
  align-items: center;
  @media ${device.tablet} {
    display: flex;
    justify-content: space-between;
    padding-top: 0px;
  }
`
const Icon = styled(FaBars)`
  color: #9e9ea7;
  height: 20px;
`

const Dropdown = styled.div`
  float: left;
  overflow: visible;
  text-align: -webkit-left;
`
const Menulink = styled(Link)`
  text-decoration: none;
`

const DropdownButton = styled.button`
  display: block;
  border: 2px solid white;
  color: black;
  outline: none;
  padding: 7px;
  text-decoration: none;
  background-color: inherit;
  font-family: inherit;
  font-size: 0.9rem;
  font-weight: 700;
  margin: 1rem;
  @media ${device.tablet} {
    font-size: 30px;
  }
  :hover {
    border-bottom: 2px solid #518084;
  }
`

const DropdownLink = styled(Link)`
  float: none;
  color: black;
  border: 2px solid white;
  padding: 10px;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 12px;
  :hover {
    border: 2px solid #518084;
  }
`

const LogoContainer = styled.div`
  width: 100px;
  text-align: left;
  margin: 0rem 4rem;
`
const MenuContainer = styled.div`
  display: flex;
  /* grid-template-columns: repeat(6, 1fr); */
  justify-content: space-evenly;
  text-align: right;
  align-items: center;
`

const DropdownContent = styled.div`
  display: none;
  position: fixed;
  align-content: left;
  background-color: #f9f9f9;
  width: 30%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
  margin: 0px;
  padding: 0px;
  ${Dropdown}:hover & {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    @media ${device.tablet} {
      display: grid;
      align-items: left;
      justify-content: left;
      grid-template-columns: repeat(1, 1fr);
      width: 90%;
    }
  }
  @media ${device.tablet} {
    background-color: white;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
  }
`

const MenuBar = props => {
  const logo = props.blog ? (
    <LogoImage imgName={"halvinliittymablogi"} />
  ) : (
    <LogoImage imgName={"halvinliittyma"} />
  )
  return (
    <>
      <NavBar>
        <MenuContainer>
          <LogoContainer>
            <Menulink to="/">{logo}</Menulink>
          </LogoContainer>
          <Dropdown>
            <Menulink to="/puhelinliittymavertailu/">
              <DropdownButton>Puhelinliittymä vertailu</DropdownButton>
            </Menulink>
            <DropdownContent>
              <DropdownLink to={"/lapselle/"}>
                Puhelinliittymä lapselle
              </DropdownLink>
              <DropdownLink to={"/ilman-nettia/"}>
                Puhelinliittymä ilman nettiä
              </DropdownLink>
              <DropdownLink to={"/mobiililaajakaista/"}>
                Mobiililaajakaista
              </DropdownLink>
              <DropdownLink to={"/prepaid/"}>Prepaid</DropdownLink>
            </DropdownContent>
          </Dropdown>
          <Dropdown>
            <Menulink to={"/nettiliittyma/"}>
              <DropdownButton>Nettiliittymävertailu</DropdownButton>
            </Menulink>
            <DropdownContent>
              <DropdownLink to={"/mokkula/"}>Mokkulat</DropdownLink>
            </DropdownContent>
          </Dropdown>
          <Dropdown>
            <Menulink to="/operaattorit/">
              <DropdownButton>Operaattorit</DropdownButton>
            </Menulink>
            <DropdownContent>
              <DropdownLink to={"/telia/"}>Telia</DropdownLink>
              <DropdownLink to={"/dna/"}>DNA</DropdownLink>
              <DropdownLink to={"/moi/"}>Moi Mobiili</DropdownLink>
              <DropdownLink to={"/elisa/"}>Elisa</DropdownLink>
              <DropdownLink to={"/liittymatarjous/"}>Tarjoukset</DropdownLink>
            </DropdownContent>
          </Dropdown>
          <Menulink to={"/suoramarkkinointikielto/"}>
            <DropdownButton>Suoramarkkinointikielto</DropdownButton>
          </Menulink>
          <Menulink to={"/nopeustesti/"}>
            <DropdownButton>Nopeustesti</DropdownButton>
          </Menulink>
          <Menulink to={"/blogi/"}>
            <DropdownButton>Blogi</DropdownButton>
          </Menulink>
        </MenuContainer>
      </NavBar>

      <NavBarMobile>
        <Link to="/">{logo}</Link>
        <Dropdown>
          <DropdownButton>
            <Icon />
          </DropdownButton>
          <DropdownContent>
            <LogoContainer>
              <Link to="/">{logo}</Link>
              <DropdownLink to={"/puhelinliittymavertailu/"}>
                Puhelinliittymä vertailu
              </DropdownLink>
              <DropdownLink to={"/nettiliittyma/"}>
                Nettiliittymävertailu
              </DropdownLink>
              <DropdownLink to={"/lapselle/"}>
                Puhelinliittymä lapselle
              </DropdownLink>
              <DropdownLink to={"/ilman-nettia/"}>
                Puhelinliittymä ilman nettiä
              </DropdownLink>
              <DropdownLink to={"/mobiililaajakaista/"}>
                Mobiililaajakaista
              </DropdownLink>
              <DropdownLink to={"/prepaid/"}>Prepaid</DropdownLink>
              <DropdownLink to={"/operaattorit/"}>Operaattorit</DropdownLink>
              <DropdownLink to={"/telia/"}>Telia</DropdownLink>
              <DropdownLink to={"/dna/"}>DNA</DropdownLink>
              <DropdownLink to={"/moi/"}>Moi Mobiili</DropdownLink>
              <DropdownLink to={"/elisa/"}>Elisa</DropdownLink>
              <DropdownLink to={"/liittymatarjous/"}>Tarjoukset</DropdownLink>
              <DropdownLink to={"/mokkula/"}>Mokkulat</DropdownLink>
              <DropdownLink to={"/suoramarkkinointikielto/"}>
                Suoramarkkinointikielto
              </DropdownLink>
              <DropdownLink to={"/nopeustesti/"}>Nopeustesti</DropdownLink>
              <DropdownLink to={"/blogi/"}>Blogi</DropdownLink>
            </LogoContainer>
          </DropdownContent>
        </Dropdown>
      </NavBarMobile>
    </>
  )
}

export default MenuBar
