import React, { useState } from "react"
import { FaArrowCircleUp } from "react-icons/fa"
import styled from "styled-components"

const Box = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  align-items: center;
  justify-content: center;
  z-index: 1000000000000000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: all 0.4s;
  opacity: 0.5;
  color: #1d3557;
  display: ${props => (props.showScroll ? "flex" : "none")};
  &:hover {
    color: #457b9d;
    opacity: 1;
  }
`

export const ScrollUp = () => {
  const [showScroll, setShowScroll] = useState(false)
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false)
    }
  }
  typeof window !== "undefined" &&
    window.addEventListener("scroll", checkScrollTop)
  const scrollTop = () => {
    typeof window !== "undefined" &&
      window.scrollTo({ top: 0, behavior: "smooth" })
  }
  return (
    <Box showScroll>
      <FaArrowCircleUp size="50px" onClick={scrollTop} />
    </Box>
  )
}
